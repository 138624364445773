<template>
  <div class="engagement-spread">
    <div class="text-h6 font-weight-bold mb-4">
      Engagement Spread
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="isThemeLight ? 'black' : null"
            v-bind="attrs"
            v-on="on"
          >
            help_outline
          </v-icon>
        </template>
        <span>
          Shows number of likes and comments account got on their posts. Useful to see growth dynamics and variation of audience engagements with respect to time.
        </span>
      </v-tooltip>
    </div>

    <v-row>
      <v-col
        v-for="(item, index) in items"
        :key="index"
        :cols="12"
        lg="6"
      >
        <v-card class="border-radius-1em box-shadow-soft">
          <v-card-title class="text-capitalize">
            {{ item }}
          </v-card-title>

          <v-card-text class="pb-0">
            <!-- render the chart component -->
            <apex-chart
              type="scatter"
              :height="220"
              :options="chartOptions"
              :series="series[item]"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              text
              small
              color="primary"
              @click="showDialog(item)"
            >
              View More
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="shouldShowDialog"
      width="500"
      scrollable
    >
      <v-card>
        <v-card-title>
          Engagement
        </v-card-title>

        <v-card-text class="make-it-scrollable">
          <v-data-table
            :headers="tableHeaders"
            :items="tableItems"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item.postUrl="{ value }">
              <a :href="value" target="_blank">
                View
              </a>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="shouldShowDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import node packages
import ApexChart from 'vue-apexcharts'
import merge from 'lodash/merge'

// Import default configurations object
import apexChartBaseOptions from '@/json/apexChartBaseOptions.js'

// Export the SFC
export default {
  // Name of the component
  name: "EngagementSpread",

  // Accept incoming data from parent
  props: {
    engagementData: {
      type: Object,
      required: true
    }
  },

  // Register the components
  components: {
    ApexChart
  },

  // Define local data variables
  data: () => ({
    shouldShowDialog: false,

    selectedItem: "comments",
    items: ["comments", "likes"],
  }),

  // Define readonly computable properties
  computed: {
    /**
     * Get the header items for the table
     *
     * @returns {Array}
     */
    tableHeaders() {
      return [
        {
          text: "Post Date",
          value: "x",
          sortable: false
        },
        {
          text: capitalizeString(this.selectedItem),
          value: "y",
          sortable: false,
        },
        {
          text: "Post",
          value: "postUrl",
          sortable: false
        },
      ]
    },

    /**
     * Get the table row items
     *
     * @returns {Array}
     */
    tableItems() {
      return this.engagementData[this.selectedItem]
        .map((item) => ({
          x: dayjs(item.x).format("LLL"),
          y: nFormatter(item.y),
          postUrl: item.postUrl
        }))
    },

    /**
     * Get the series data for the chart
     *
     * @returns {Array}
     */
    series() {
      return {
        comments: this.engagementData.comments.map((item, index) => {
          return {
            color: "#2196F3",
            name: "abc" + index,
            data: [[item.x, item.y]]
          }
        }),

        likes: this.engagementData.likes.map((item, index) => {
          return {
            color: "#2196F3",
            name: "abc" + index,
            data: [[item.x, item.y]]
          }
        }),
      }
    },

    /**
     * Get chart's configuration options
     *
     * @returns {Object}
     */
    chartOptions() {
      return merge(apexChartBaseOptions(), {
        tooltip: {
          x: {
            show: true,
            formatter: (seriesName, opts) => dayjs(seriesName).format("LL"),
            title: {
              formatter: () => "",
            },
          },
          marker: { show: false },
          y: {
            formatter: (seriesName) => numeral.fShortenNumber(seriesName),
            title: {
              formatter: () => "",
            },
          },
        },

        colors: ["#ffa726"],

        dataLabels: {
          enabled: false
        },

        "legend": {
          "show": false,
        },

        grid: {
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          },
        },

        "stroke": {
          "width": 6,
          "curve": "smooth",
          "lineCap": "round"
        },

        yaxis: {
          tickAmount: 5,
          type: "numeric",
          title: {
            text: "Count",
          },
          labels: {
            formatter: (val) => numeral.fShortenNumber(val).toLocaleUpperCase(),
          },
        },
        xaxis: {
          tickAmount: 7,
          type: "datetime",
          title: {
            text: "Period",
          },
        },
      })
    }
  },

  // Define local method functions
  methods: {
    /**
     * Toggle whether to show the dialog or not
     *
     * @param {Object} item
     * @returns {void}
     */
    showDialog(item) {
      this.selectedItem = item
      this.shouldShowDialog = true
    }
  }
}
</script>
